<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-3">
                <h5>Invoices</h5>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-4 p-lg-4">
                <div v-if="isLoading" class="workingTitle">
                    <div class="card">
                        <h5 class="muted-text">Loading Invoice</h5>
                        <div class="custom-skeleton p-p-4">
                            <ul class="p-m-0 p-p-0">
                                <li class="p-mb-3">
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                                <li class="p-mb-3">
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                                <li class="p-mb-3">
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="p-d-flex">
                                        <Skeleton shape="circle" size="4rem" class="p-mr-2"></Skeleton>
                                        <div style="flex: 1">
                                            <Skeleton width="100%" class="p-mb-2"></Skeleton>
                                            <Skeleton width="75%"></Skeleton>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="card p-fluid card-height">
                        <Listbox v-model="selectedInvoice" :options="invoicesView">
                            <template #header>
                                <div class="filterControls">
                                    <div class="filterShow">Show:</div>
                                    <div class="filterButton" @click="filter(null, null)"><span
                                            class="tinyCircle"></span>All</div>
                                    <div class="filterButton" @click="filter('paymentStatus', 'DUE')"><span
                                            class="tinyCircle invoiceDue"></span>Due</div>
                                    <div class="filterButton" @click="filter('paymentStatus', 'OVERDUE')"><span
                                            class="tinyCircle invoiceOverdue"></span>Overdue</div>
                                    <div class="filterButton" @click="filter('paymentStatus', 'PAID')"><span
                                            class="tinyCircle invoicePaid"></span>Paid</div>
                                </div>
                            </template>

                            <template #empty>
                                <div class="noInvoicesFoundMsg">
                                    No invoices found
                                </div>
                            </template>


                            <template #option="slotprops">
                                <div class="p-d-flex p-py-3" @mouseover="showArrow = slotprops.option.sourceInvoiceNumber"
                                    @mouseout="showArrow = null">
                                    <div class="p-d-flex p-mr-2 p-ai-center">
                                        <Avatar class="p-mr-2 p-ml-3" size="medium" :class="[
                                            slotprops.option.paymentStatus == 'OVERDUE' && 'invoiceOverdue',
                                            slotprops.option.paymentStatus == 'DUE' && 'invoiceDue',
                                            slotprops.option.paymentStatus == 'PAID' && 'invoiceStatusOkay',
                                        ]" shape="circle"></Avatar>
                                        <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                            <div>
                                                <b>#{{ slotprops.option.sourceInvoiceNumber }}</b>
                                            </div>
                                            <small class="muted-text">{{
                                                formatCurrency(slotprops.option.totalAmount)
                                            }}</small>
                                        </div>
                                    </div>
                                    <div class="p-mr-2" :class="{ 'p-ml-auto p-mr-2': !isRTL, 'p-mr-auto p-ml-2': isRTL }">
                                        <small class="muted-text">Due date</small><br />
                                        <small :class="[
                                            slotprops.option.paymentStatus == 'OVERDUE' && 'invoiceOverdueColor',
                                            slotprops.option.paymentStatus == 'DUE' && 'invoiceDueColor',
                                            slotprops.option.paymentStatus == 'PAID' && 'invoiceStatusOkayColor',
                                        ]">{{ formatDate(slotprops.option.dueDate) }}</small>
                                    </div>
                                    <div class="p-mr-2 arrowBlock">
                                        <div id="triangle-right" v-show="showArrow == slotprops.option.sourceInvoiceNumber">
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Listbox>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-8 p-lg-8">
                <div v-if="isLoading" class="workingTitle">
                    <div class="card">
                        <div class="custom-skeleton p-p-4">
                            <div class="p-d-flex p-jc-between p-mt-3">
                                <Skeleton width="10rem" height="2rem"></Skeleton>
                                <Skeleton width="10rem" height="2rem"></Skeleton>
                            </div>
                            <div class="p-d-flex p-mb-3">
                                <div>
                                    <Skeleton width="10rem" height="0.5rem" class="p-mb-2"></Skeleton>
                                    <Skeleton width="5rem" height="1rem" class="p-mb-2"></Skeleton>
                                    <Skeleton width="10rem" height="1rem" class="p-mb-2"></Skeleton>
                                    <Skeleton height="1rem"></Skeleton>
                                </div>
                            </div>
                            <Skeleton class="p-mb-2" width="80%"></Skeleton>
                            <Skeleton width="70%" height="150px"></Skeleton>
                        </div>
                    </div>
                </div>
                <div v-else-if="selectedInvoice == null">
                    <div class="card">
                        <span class="blankInvoice">No invoice to display</span>
                    </div>
                </div>
                <div v-else>
                    <div class="card p-fluid">
                        <div class="p-d-flex p-jc-between">
                            <div class="p-mr-2">
                                <h5 class="muted-text">Invoice #{{ selectedInvoice.sourceInvoiceNumber }}</h5>
                            </div>
                            <div class="p-ml-auto">
                                <h5 class="p-mt-1 p-ml-5 muted-text">Amount: {{
                                    formatCurrency(selectedInvoice.totalAmount)
                                }}</h5>
                            </div>
                        </div>
                        <div class="p-d-flex p-flex-column">
                            <div class="p-mb-2 p-d-flex p-flex-column">
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">Transaction date</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate muted-text">
                                            {{ formatDate(selectedInvoice.transactionDate) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">Terms</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate muted-text">
                                            {{ selectedInvoice.paymentTerms }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">Due date</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate muted-text">
                                            {{ formatDate(selectedInvoice.dueDate) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-mb-2">
                                    <div class="p-d-flex p-jc-center">
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="muted-text">Status</span>
                                        </div>
                                        <div class="p-mr-2">
                                            <span class="muted-text">:</span>
                                        </div>
                                        <div class="p-mr-2 detailsTransactionDate">
                                            <span class="invoiceStatus" :class="[
                                                selectedInvoice.paymentStatus == 'OVERDUE' && 'invoiceOverdue',
                                                selectedInvoice.paymentStatus == 'DUE' && 'invoiceDue',
                                                selectedInvoice.paymentStatus == 'PAID' && 'invoiceStatusOkay',
                                                selectedInvoice.paymentStatus == 'CANCELLED' && 'invoiceCancelled',
                                            ]">
                                                {{ selectedInvoice.paymentStatus }}
                                            </span>
                                            <span class="detailsPaymentUrl payLink p-ml-5"><a target="_blank"
                                                    :href="selectedInvoice.paymentUrl">View &amp; Pay</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-mb-2 p-d-flex p-flex-column">
                            <div class="p-mb-2">
                                <span class="muted-text">Transactions :</span>
                                <hr class="rounded" />
                            </div>
                            <div class="p-mb-2">
                                <ul class="p-m-0 p-p-0 p-d-flex p-flex-column">
                                    <li class="p-mb-2 p-d-flex detailsInvoiceLine" v-for="item in selectedInvoice.lines"
                                        :key="item.sourceLineId">
                                        <div class="p-mr-2 p-text-right muted-text lineNumber"
                                            style="flex: 2; display: inline-block">
                                            Line #{{ item.sourceLineId }}:
                                        </div>

                                        <div class="p-mr-6 p-text-right muted-text lineAmount"
                                            style="flex: 1; display: inline-block">
                                            {{ formatCurrency(item.amount) }}
                                        </div>

                                        <div class="p-mr-2 p-ml-2 p-text-left muted-text"
                                            style="flex: 4; display: inline-block">
                                            {{ item.description }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" v-if="additionalInfo != null">
                    <div class="card">
                        <Accordion v-if="additionalInfo.iframe">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-info-circle " style="font-weight: 900;"></i>
                                    <span
                                        style="margin-left: 10px; font-size: 1.2em; font-weight: 700; letter-spacing: 0.1em;">
                                        Additional Information
                                    </span>
                                </template>
                                <div>
                                    <div class="addlInfoDescription">{{ additionalInfo.iframe.description }}
                                        <span style="margin-left: 1.3em;">
                                        (<a target="_blank" style="color:#2a9067;" :href="additionalInfo.iframe.source">View in new tab</a>)</span>
                                    </div>
                                    <iframe :src="additionalInfo.iframe.source" style="width:100%; height:800px;" />


                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import MarketplaceApiService from '../service/MarketplaceApiService';

export default {
    data() {
        return {
            invoices: null,
            invoicesView: null,
            selectedInvoice: null,
            accountDetails: null,
            accountName: '{working...}',
            isLoading: true,
            expandedRows: [],
            showArrow: null,
            emptyInvoice: false,
            hasAdditionalInfo: true,
            invoiceViewParams: {
                sort: {
                    paramName: "transactionDate",
                    ascending: false
                },
                filter: {
                    paramName: null,
                    paramValue: null
                }
            },
            additionalInfo: null
        };
    },

    created() {
        this.apiService = new MarketplaceApiService();
    },

    updated() { },

    watch: {
        $route() {
            this.refreshInvoiceData();
        },
        invoices: {
            handler(newValue) {
                if (newValue != null && newValue.length == 0) {
                    this.emptyInvoice = true;
                } else {
                    this.emptyInvoice = false;
                }
            },
            deep: true,
        },
    },

    apiService: null,

    mounted() {
        this.refreshInvoiceData();
    },

    methods: {
        async refreshInvoiceData() {
            this.invoices = null;
            this.isLoading = true;
            this.invoicesView = null;
            this.selectedInvoice = null;

            await this.apiService.getMemberInvoices().then((data) => {
                this.invoices = data;
                if (data && data != null && data.length > 0) {
                    this.refreshInvoiceView();
                    //console.log("this.invoicesView", this.invoicesView);

                    //set first as the "selected" one to display
                    this.selectedInvoice = this.invoicesView.length ? this.invoicesView[0] : null;

                }
                //console.log("this.invoicesView", this.invoicesView);
            });

            this.apiService.getAccountDetails().then((data) => {
                this.accountDetails = data;
                this.accountName = this.accountDetails.name;
                //console.log("this.accountDetails", this.accountDetails);
                this.additionalInfo = JSON.parse(this.accountDetails.integrationDetails).settings?.invoicesAdditionalInfo ?? null;
                //console.log("this.additionalInfo", this.additionalInfo != null);
            });

            this.isLoading = false;
        },

        formattedDate(dateString) {
            var dt = new Date(dateString);
            dt.setDate(dt.getDate() - 350);
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return dt.toLocaleDateString('en-US', options);
        },

        writeInvoices() {
            //console.log(this.invoices);
        },
        formatCurrency(value) {
            if (value) return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        formatDate(value) {
            if (value) {
                var dt = new Date(value);
                return dt.toLocaleDateString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            }
        },
        displayInvoiceDetails(data) {
            this.invoiceData = data;
        },

        filter(paramName, paramValue) {
            if (paramName && paramValue) {  // neither is null
                this.invoiceViewParams.filter.paramName = paramName;
                this.invoiceViewParams.filter.paramValue = paramValue;
            }
            else {
                this.invoiceViewParams.filter.paramName = null;
                this.invoiceViewParams.filter.paramValue = null;
            }

            this.refreshInvoiceView();
        },

        refreshInvoiceView() {

            let sortByProperty = this.invoiceViewParams.sort.paramName;

            let ascDesc = this.invoiceViewParams.sort.ascending ? 1 : -1;

            let tempView = this.invoices.sort(function (a, b) {
                if (a[sortByProperty] < b[sortByProperty]) { return -ascDesc; }
                if (a[sortByProperty] > b[sortByProperty]) { return ascDesc; }
                // if above are equal, also secondary-sort by sourceInvoiceId
                if (a['sourceInvoiceId'] < b['sourceInvoiceId']) { return -ascDesc; }
                if (a['sourceInvoiceId'] > b['sourceInvoiceId']) { return ascDesc; }
                // if nothing else, this
                return 0;
            });

            //console.log("this.invoiceViewParams", JSON.stringify(this.invoiceViewParams, null, 2));
            if (this.invoiceViewParams.filter.paramName && this.invoiceViewParams.filter.paramValue) {
                let filterParam = this.invoiceViewParams.filter.paramName;
                let filterVal = this.invoiceViewParams.filter.paramValue;
                tempView = tempView.filter(b => b[filterParam] == filterVal);
                console.log("tempView", tempView);
            }

            console.log(tempView);
            this.invoicesView = tempView;
            this.selectedInvoice = this.invoicesView[0] ?? null;
            console.log("this.selectedInvoice", this.selectedInvoice);
        },


    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
    },
};
</script>

<style lang="scss" scoped>
.card-height {
    max-height: 570px;
    height: auto;
    overflow-y: auto;
    padding: 0;

    ul>li {
        padding: 0 1px 0 1px;
    }
}

#triangle-right {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 15px solid #a0d2fa;
    border-bottom: 20px solid transparent;
}

.arrowBlock {
    width: 20px;
    height: 20px;
}

.rounded {
    border-top: 3px solid #bbb;
    border-radius: 3px;
}

li.hoverclass:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.workingTitle {
    color: #0f5b60;
}

.detailsInvoice {
    cursor: default;
}

.invoiceHeader {
    display: inline-block;
}

.invoiceNumber {
    display: inline-block;
    width: 250px;
    border-bottom: 1px solid #999999;
}

.detailsTransactionDate {
    display: inline-block;
    width: 250px;
}

.invoiceTotal {
    display: inline-block;
    width: 165px;
    border-bottom: 1px solid #999999;
}

.summaryDetails {
    padding-left: 17px;
    font-size: 90%;
}

.detailsInvoice {
    padding: 12px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.detailsInvoiceLine {
    margin: 5px;
    margin-left: 20px;
    font-size: 85%;
}

.detailsInvoiceLine div {
    color: #444444;
    margin-right: 7px;
}

.payLink {
    width: 250px;
}

.payLink a {
    color: green;
    font-weight: bold;
    text-align: right;
}

.payLink a:hover {
    color: blue;
}

.lineNumber {
    width: 60px;
    display: inline-block;
}

.lineAmount {
    width: 100px;
    display: inline-block;
}

.tinyCircle {
    border: 0px solid transparent;
    background-color: lightgray;
    height: 16px;
    width: 16px !important;
    border-radius: 8px;
    display: inline-block;
    margin: 5px 5px 5px 25px;
    cursor: pointer;
}



.invoiceStatus {
    font-weight: bold;
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
}

.invoiceStatusOkay {
    color: #ffffff;
    background-color: #57be92;
}

.invoiceOverdue {
    color: #ffffff;
    background-color: #fd705e;
}

.invoiceDue {
    color: #ffffff;
    background-color: #2196f3;
}

.invoiceCancelled {
    color: #ffffff;
    background-color: #999999;
}

.ml-100 {
    margin-right: 20px;
}

@media screen and (max-width: 768px) {
    .card-height {
        max-height: 200px;
        height: auto;
        overflow-y: auto;
        padding: 0;

        ul>li {
            padding: 0 5px 0 5px;
        }
    }

    #triangle-right {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 10px solid #a0d2fa;
        border-bottom: 15px solid transparent;
    }
}


.filterControls {
    border-bottom: 1px solid grey;
    padding: 4px;
    display: flex;
    align-items: center;
}

.filterControls span {
    cursor: pointer;
}

.filterShow {
    padding-left: 20px;
}

.filterButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.invoicePaid {
    color: #ffffff;
    background-color: #57be92;
}

.noInvoicesFoundMsg {
    color: #aaaaaa;
    padding-left: 13px;
    font-size: 1.2rem;
    font-style: italic;
}

.blankInvoice {
    color: #aaaaaa;
    padding-left: 13px;
    font-size: 1.2rem;
    font-style: italic;
}

.addlInfoDescription {
    padding-bottom: 10px;
    font-size: 1.2rem;
    font-style: italic;
}
</style>
